<template>
  <div>
    <KTCodePreview v-bind:title="'Cập nhật chi tiết IMEI'">
      <template v-slot:preview>
        <b-card
          no-body
          class="full-width"
        >
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div
                class="wizard-nav"
                style="background-color: #eef0f8"
              >
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row py-8 px-8 py-lg-15 px-lg-10">
                    <div class="col-xl-12">
                      <div
                        class="pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <b-form
                          ref="form"
                          lazy-validation
                          class="row"
                        >
                          <!-- Input session -->
                          <b-container class="bv-example-row">
                            <b-row>
                              <b-col>
                                <b-col>
                                  <b-form-group>
                                    <label>Kho:</label>
                                    <Autosuggest
                                      :model="searchStock"
                                      :suggestions="filteredOptions"
                                      :placeholder="'kho'"
                                      @select="onSelected"
                                      @change="onInputChange"
                                      suggestionName="suggestionName"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group>
                                    <label for="input-name">IMEI:</label>
                                    <b-form-input
                                      v-model="imei"
                                      type="text"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group>
                                    <label>Giá nhập:</label>
                                    <b-form-input
                                      v-model="originalPrice"
                                      type="text"
                                      size="sm"
                                      v-mask="mask"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group>
                                    <label>Giá bán:</label>
                                    <b-form-input
                                      v-model="sellingPrice"
                                      type="text"
                                      size="sm"
                                      v-mask="mask"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group>
                                    <label>Trạng thái:</label>
                                    <b-form-select
                                      class="select-style"
                                      v-model="status.selected"
                                      :options="status.options"
                                      size="sm"
                                    ></b-form-select>
                                  </b-form-group>
                                </b-col>
                              </b-col>
                              <b-col></b-col>
                              <b-col></b-col>
                              <b-col></b-col>
                            </b-row>
                          </b-container>
                        </b-form>
                      </div>

                      <div
                        class="pb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <b-container class="bv-example-row">
                          <b-table
                            :fields="historyFields"
                            :items="histories"
                            class="table-bordered table-hover col-md-6"
                          >
                            <template v-slot:cell(updatedBy)="row">
                              <p>{{ row.item.updatedBy }}</p>
                              <p>{{ row.item.updatedAt }}</p>
                            </template>
                            <template v-slot:cell(action)="row">
                              <p>
                                {{
                                  row.item.action === 1
                                    ? 'Sửa IMEI'
                                    : 'Xóa IMEI'
                                }}
                              </p>
                            </template>

                            <template v-slot:cell(detail)="row">
                              <div
                                class="orderCode"
                                @click="viewHistoryDetail(row.item.id)"
                                style="cursor: pointer; color: #3699ff"
                              >
                                <span v-text="row.item.id"></span>
                              </div>
                            </template>
                          </b-table>
                        </b-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
      </template>
      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
            >Lưu</b-button>
            <router-link
              to="/products"
              tag="button"
            >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </router-link>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="imei-history-modal"
          hide-footer
          title="Lịch sử chỉnh sửa"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-container class="bv-example-row">
            <table
              border="1"
              style="width: 80%"
              class="
                table table-bordered table-vertical-center table-hover
                mb-10
              "
            >
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead class="bg-secondary">
                <tr>
                  <th rowspan="2">Thông tin IMEI</th>
                </tr>
                <tr>
                  <th>Trước khi sửa</th>
                  <th>Sau khi sửa</th>
                </tr>
              </thead>
              <tbody v-if="diffInfo._">
                <tr v-show="diffInfo._.storeName.status === 'MODIFIED'">
                  <th class="header-color">Cửa hàng</th>
                  <td>{{ diffInfo._.storeName.original }}</td>
                  <td>{{ diffInfo._.storeName.current }}</td>
                </tr>
                <tr v-show="diffInfo._.imeiNo.status === 'MODIFIED'">
                  <th class="header-color">Mã IMEI</th>
                  <td>{{ diffInfo._.imeiNo.original }}</td>
                  <td>{{ diffInfo._.imeiNo.current }}</td>
                </tr>
                <tr v-show="isModified(diffInfo._.status.status)">
                  <th class="header-color">Trạng thái</th>
                  <td>{{ diffInfo._.status.original }}</td>
                  <td>{{ diffInfo._.status.current }}</td>
                </tr>
                <tr v-show="isModified(diffInfo._.originalPrice.status)">
                  <th class="header-color">Giá nhập</th>
                  <td class="text-right">
                    {{ convertPrice(diffInfo._.originalPrice.original) }}
                  </td>
                  <td class="text-right">
                    {{ convertPrice(diffInfo._.originalPrice.current) }}
                  </td>
                </tr>
                <tr v-show="isModified(diffInfo._.sellingPrice.status)">
                  <th class="header-color">Giá bán</th>
                  <td class="text-right">
                    {{ convertPrice(diffInfo._.sellingPrice.original) }}
                  </td>
                  <td class="text-right">
                    {{ convertPrice(diffInfo._.sellingPrice.current) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-container>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import ApiService from '@/core/services/api.service';
import {
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  makeToastFaile,
  convertPrice,
} from '@/utils/common';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import moment from 'moment';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  data() {
    return {
      id: '',
      selectedStore: null,
      imei: '',
      originalPrice: '',
      sellingPrice: '',
      status: {
        selected: null,
        options: [
          { value: null, text: 'Hãy chọn trạng thái' },
          { value: '1', text: 'Mới' },
          { value: '2', text: 'Đã bán' },
          { value: '3', text: 'Đang vận chuyển' },
          { value: '4', text: 'Lỗi' },
          { value: '5', text: 'Đã trả NCC' },
          { value: '6', text: 'Đang chuyển kho' },
          { value: '7', text: 'Đã trả bảo hành' },
        ],
      },
      filteredOptions: [],
      options: [],
      searchStock: '',
      mask: currencyMask,
      historyFields: [
        {
          key: 'updatedBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Hành động',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'text-center',
        },
      ],
      histories: [],
      historyId: null,
      diffInfo: {},
      diffItem: {},
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getProductIMEIById(this.id);
    this.fetchStore();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'IMEI', route: '/imeis' },
      { title: 'Danh sách IMEI', route: '/imeis' },
      { title: 'Cập nhật chi tiết IMEI' },
    ]);
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    this.fetchIMEIHistory();
  },
  methods: {
    convertPrice,
    onSubmit: async function () {
      const data = {
        storeId: this.selectedStore,
        imeiNo: this.imei,
        originalPrice: this.originalPrice ? unMaskPrice(this.originalPrice) : 0,
        sellingPrice: this.sellingPrice ? unMaskPrice(this.sellingPrice) : 0,
        status: this.status.selected,
      };
      ApiService.post('productEmeiStock' + '/' + this.id, data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              name: 'list-imeis',
              query: { code: this.imei },
            });
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
          }
        });
    },
    fetchStore: async function () {
      this.options = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          const store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options.push(store);
        });
        this.filteredOptions = [...this.options];
      });
    },
    getProductIMEIById: async function (productImeiStockId) {
      ApiService.setHeader();
      ApiService.query(
        'productEmeiStock/getProductEmei' + '/' + productImeiStockId,
      ).then((response) => {
        this.selectedStore = response.data.data.storeId;
        this.searchStock = response.data.data.storeName;
        this.imei = response.data.data.imeiNo;
        this.originalPrice = response.data.data.originalPrice;
        this.sellingPrice = response.data.data.sellingPrice;
        this.status.selected = response.data.data.status;
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      const filteredData = this.options.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
      });

      this.filteredOptions = [...filteredData];
    },
    fetchIMEIHistory: async function () {
      const params = {
        //  loại SP IMEI
        type: 4,
      };
      await ApiService.setHeader();
      await ApiService.query('/histories/getByReferenceId/' + this.id, {
        params,
      }).then((res) => {
        this.histories = res.data.data.map((x) => {
          return {
            id: x.id,
            type: x.type,
            referenceId: x.referenceId,
            action: x.action,
            description: x.description,
            originalValue: x.originalValue,
            newValue: x.newValue,
            updatedBy: x.updatedBy,
            createdAt: moment(String(x.createdAt)).format('DD/MM/YYYY HH:mm'),
            updatedAt: moment(x.updatedAt).format('DD/MM HH:mm'),
          };
        });
      });
    },
    viewHistoryDetail(id) {
      this.historyId = id;
      this.getDetailHistory();
    },
    showHistoryModal() {
      this.$refs['imei-history-modal'].show();
    },
    isModified(value) {
      return value === 'MODIFIED';
    },
    getDetailHistory: async function () {
      const params = {
        //  loại hóa đơn
        type: 4,
      };
      this.onLoading = true;
      await ApiService.setHeader();
      await ApiService.query('/histories/' + this.historyId, { params }).then(
        (res) => {
          let { diffInfo, diffItem } = res.data.data;
          this.diffInfo = diffInfo;
          this.diffItem = diffItem;
          this.showHistoryModal();
        },
      );
      this.onLoading = false;
    },
  },
};
</script>

<style scoped>
.form-group label {
  font-weight: 600;
}
.orderCode:hover {
  text-decoration: underline;
}
</style>
<style scoped>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
